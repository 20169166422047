import React, { useContext, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import {
  ClassDetailQuery,
  ClassDetailQuery$variables,
} from "./__generated__/ClassDetailQuery.graphql";
import { DisposeFn } from "relay-runtime";
import { useParams } from "react-router-dom";

//import styles from "./ClassDetail.module.scss";
import { ClassesSlideOverTitleContext } from "./Classes";

type Props = {
  initialQueryRef: PreloadedQuery<ClassDetailQuery> | null | undefined;
  fetch: (variables: ClassDetailQuery$variables) => void;
  dispose: DisposeFn;
};

type PropsFinal = {
  initialQueryRef: PreloadedQuery<ClassDetailQuery>;
};

const ClassDetailInner = ({ initialQueryRef }: PropsFinal) => {
  //const [reconfigureRoom, reconfiguringRoom] = useReconfigureRoomMutation();
  //const navigate = useNavigate()

  const data = usePreloadedQuery(
    graphql`
      query ClassDetailQuery {
        self {
          id
        }
      }
    `,
    initialQueryRef
  );

  const titleSetContext = useContext(ClassesSlideOverTitleContext);

  titleSetContext &&
    /*data.school?.name &&*/ titleSetContext(/*data.school.name*/ "uwu");

  return (
    <>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
};

const ClassDetail = ({ initialQueryRef, fetch, dispose }: Props) => {
  const { schoolId, classId } = useParams();

  useEffect(() => {
    if (!schoolId) return;
    if (!classId) return;
    fetch({ school: schoolId, class: classId });

    return () => {
      dispose();
    };
  }, [schoolId, classId, dispose, fetch]);

  return initialQueryRef ? (
    <ClassDetailInner initialQueryRef={initialQueryRef} />
  ) : null;
};

export default ClassDetail;
