import React, { useState } from "react";
import { useNavigate, useOutlet } from "react-router-dom";

import styles from "./Schools.module.scss";
//import RoomsTable from "./RoomsTable";
import { PreloadedQuery, usePreloadedQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SchoolsQuery } from "./__generated__/SchoolsQuery.graphql";
import { X } from "lucide-react";
import { LoaderSuspense } from "../../common/Loader";
import SchoolsTable from "./SchoolsTable";

type Props = {
  initialQueryRef: PreloadedQuery<SchoolsQuery>;
};

const Schools = ({ initialQueryRef }: Props) => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const data = usePreloadedQuery(
    graphql`
      query SchoolsQuery($first: Int, $after: ID) {
        ...SchoolsTableFragment
        self {
          role
        }
      }
    `,
    initialQueryRef
  );

  const defaultTitle = "Details";

  const [title, setTitle] = useState(defaultTitle);

  return (
    <div className={styles.schoolsContainer}>
      <div
        className={
          styles.schoolsOverview + (outlet ? " " + styles.leaveSpace : "")
        }
      >
        <h1>Meine Schulen</h1>

        <SchoolsTable initialQueryRef={data} />
      </div>

      <div className={styles.slideOver + (outlet ? " " + styles.active : "")}>
        <SchoolsSlideOverTitleContext.Provider value={setTitle}>
          <div className={styles.slideOverHeader}>
            <span>{title}</span>
            <button onClick={() => navigate("/schools")}>
              <X />
            </button>
          </div>
          <div className={styles.slideOverContent}>
            <LoaderSuspense>{outlet}</LoaderSuspense>
          </div>
        </SchoolsSlideOverTitleContext.Provider>
      </div>
    </div>
  );
};

export const SchoolsSlideOverTitleContext = React.createContext<React.Dispatch<
  React.SetStateAction<string>
> | null>(null);

export default Schools;
