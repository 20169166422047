import React from "react";
import { usePaginationFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import styles from "./DashMyClasses.module.scss";
import { ComponentDashMyClasses } from "./__generated__/ComponentDashMyClasses.graphql";
import { DashMyClassesFragment$key } from "./__generated__/DashMyClassesFragment.graphql";
import List from "../../common/List";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

type Props = {
  initialQueryRef: DashMyClassesFragment$key;
  className?: string;
};

const DashMyClasses = (props: Props) => {
  //const {t} = useTranslation()

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    ComponentDashMyClasses,
    DashMyClassesFragment$key
  >(
    graphql`
      fragment DashMyClassesFragment on Query
      @refetchable(queryName: "ComponentDashMyClasses") {
        self {
          school {
            id
            classes(after: $after, first: $first)
              @connection(key: "ComponentDashMyClasses_classes") {
              edges {
                node {
                  id
                  name
                  school {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    props.initialQueryRef
  );

  return (
    <div className={styles.dashMyClasses + " " + (props.className || "")}>
      <h2>Meine Klassen</h2>

      <List
        className={styles.list}
        hasNext={hasNext}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
      >
        {data.self.school?.classes?.edges.map((edge) => {
          return (
            <Link
              className={styles.class}
              key={edge.node.id}
              to={`/schools/${edge.node.school.id}/classes/${edge.node.id}`}
            >
              <div className={styles.nameRow}>
                <span className={styles.name}>{edge.node.name}</span>
              </div>
              <span className={styles.id}>{edge.node.id}</span>
            </Link>
          );
        })}
      </List>

      <Link to={`/schools/${data.self.school?.id}/classes/new`}>
        <Plus /> Neue Klasse
      </Link>
    </div>
  );
};

export default DashMyClasses;
