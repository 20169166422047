import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import RequireAuth from "./features/auth/RequireAuth";
import AuthLayout from "./layouts/AuthLayout";
import PanelLayout from "./layouts/PanelLayout";
import LoginView from "./components/auth/LoginView";
import RegisterView from "./components/auth/RegisterView";
import Dashboard from "./components/panel/dashboard/Dashboard";
import { useQueryLoader, useRelayEnvironment } from "react-relay";
import DashboardQueryGraphql, {
  DashboardQuery,
} from "./components/panel/dashboard/__generated__/DashboardQuery.graphql";
import { useAppSelector } from "./app/hooks";
import { selectAuth } from "./features/auth/authSlice";
import Schools from "./components/panel/schools/Schools";
import SchoolsQueryGraphql, {
  SchoolsQuery,
} from "./components/panel/schools/__generated__/SchoolsQuery.graphql";
import SchoolDetail from "./components/panel/schools/SchoolDetail";
import SchoolDetailQueryGraphql, {
  SchoolDetailQuery,
} from "./components/panel/schools/__generated__/SchoolDetailQuery.graphql";
import ClassesQueryGraphql, {
  ClassesQuery,
} from "./components/panel/schools/classes/__generated__/ClassesQuery.graphql";
import Classes from "./components/panel/schools/classes/Classes";
import ClassDetail from "./components/panel/schools/classes/ClassDetail";
import ClassDetailQueryGraphql, {
  ClassDetailQuery,
} from "./components/panel/schools/classes/__generated__/ClassDetailQuery.graphql";

const App = () => {
  //const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const environment = useRelayEnvironment();

  const [dashboardInitialState, loadQuery, disposeQuery] =
    useQueryLoader<DashboardQuery>(DashboardQueryGraphql);

  const [schoolsInitialState, loadSchoolsQuery, disposeSchoolsQuery] =
    useQueryLoader<SchoolsQuery>(SchoolsQueryGraphql);

  const [
    schoolDetailInitialState,
    loadSchoolDetailQuery,
    disposeSchoolDetailQuery,
  ] = useQueryLoader<SchoolDetailQuery>(SchoolDetailQueryGraphql);

  const [classesInitialState, loadClassesQuery, disposeClassesQuery] =
    useQueryLoader<ClassesQuery>(ClassesQueryGraphql);

  const [
    classDetailInitialState,
    loadClassDetailQuery,
    disposeClassDetailQuery,
  ] = useQueryLoader<ClassDetailQuery>(ClassDetailQueryGraphql);

  useEffect(() => {
    if (auth.jwt !== null) {
      loadQuery({});
      loadSchoolsQuery({});
      return;
    }

    disposeQuery();
    disposeSchoolsQuery();
    environment.getStore().notify(undefined, true);
  }, [
    auth,
    disposeQuery,
    environment,
    loadQuery,
    loadSchoolsQuery,
    disposeSchoolsQuery,
  ]);

  return (
    <Routes>
      <Route path={"/auth"} element={<AuthLayout />}>
        <Route path={"login"} element={<LoginView />} />
        <Route path={"register"} element={<RegisterView />} />
      </Route>
      <Route path={"/"} element={<PanelLayout />}>
        <Route
          path={""}
          element={
            <RequireAuth>
              {dashboardInitialState && (
                <Dashboard initialQueryRef={dashboardInitialState} />
              )}
            </RequireAuth>
          }
        />
        <Route
          path={"/schools"}
          element={
            <RequireAuth>
              {schoolsInitialState && (
                <Schools initialQueryRef={schoolsInitialState} />
              )}
            </RequireAuth>
          }
        >
          <Route path={":schoolId"}>
            <Route
              path={""}
              element={
                <RequireAuth>
                  <SchoolDetail
                    initialQueryRef={schoolDetailInitialState}
                    fetch={loadSchoolDetailQuery}
                    dispose={disposeSchoolDetailQuery}
                  />
                </RequireAuth>
              }
            />
          </Route>
        </Route>
        <Route
          path={"/schools/:schoolId/classes"}
          element={
            <RequireAuth>
              <Classes
                initialQueryRef={classesInitialState}
                fetch={loadClassesQuery}
                dispose={disposeClassesQuery}
              />
            </RequireAuth>
          }
        >
          <Route
            path={":classId"}
            element={
              <RequireAuth>
                <ClassDetail
                  initialQueryRef={classDetailInitialState}
                  fetch={loadClassDetailQuery}
                  dispose={disposeClassDetailQuery}
                />
              </RequireAuth>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
