/**
 * @generated SignedSource<<bca8f0515842bb18e49a4026c5180a86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RegisterInput = {
  email: string;
  firstName: string;
  inviteCode: string;
  lastName: string;
  password: string;
  username: string;
};
export type RegisterMutation$variables = {
  registerInput: RegisterInput;
};
export type RegisterMutation$data = {
  readonly register: string;
};
export type RegisterMutation = {
  response: RegisterMutation$data;
  variables: RegisterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registerInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "registerInput"
      }
    ],
    "kind": "ScalarField",
    "name": "register",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba63e13cd99131436cdd3fc914a4d22c",
    "id": null,
    "metadata": {},
    "name": "RegisterMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterMutation(\n  $registerInput: RegisterInput!\n) {\n  register(input: $registerInput)\n}\n"
  }
};
})();

(node as any).hash = "885a302facf43582a5057a8825228e5c";

export default node;
