import React, { useState } from "react";

import styles from "./AuthViews.module.scss";

import { ReactComponent as Logo } from "../../logo.svg";
import { Link, useLocation } from "react-router-dom";
import { AuthLocationState } from "../../layouts/AuthLayout";
import { useAppDispatch } from "../../app/hooks";
import Helmet from "react-helmet";
import { logIn } from "../../features/auth/authSlice";
import RegisterMutation from "../../mutations/RegisterMutation";
import { Key } from "lucide-react";

const RegisterView = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invite, setInvite] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const locationState = location.state as AuthLocationState;

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      const res = await RegisterMutation(
        firstName,
        lastName,
        email,
        username,
        password,
        invite
      );

      const jwt = res.register;

      dispatch(logIn(jwt));
    } catch (e: any) {
      setError("Ein Fehler ist aufgetreten: " + e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo width={64} height={64} />
      <Helmet>
        <title>Bei Co2Admin registrieren</title>
      </Helmet>

      <h1>Registrieren</h1>

      {loading && (
        <div className={styles.loader}>
          <Key />
          <span>Registriere...</span>
        </div>
      )}

      {!loading && (
        <>
          {error !== "" && <span className={styles.error}>{error}</span>}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className={styles.authForm}
          >
            <input
              onChange={(ev) => setFirstName(ev.target.value)}
              value={firstName}
              placeholder={"Vorname"}
              autoCapitalize={"no"}
              autoCorrect={"no"}
            />
            <input
              onChange={(ev) => setLastName(ev.target.value)}
              value={lastName}
              placeholder={"Nachname"}
              autoCapitalize={"no"}
              autoCorrect={"no"}
            />
            <input
              onChange={(ev) => setEmail(ev.target.value)}
              value={email}
              placeholder={"E-Mail-Adresse"}
              autoCapitalize={"no"}
              autoCorrect={"no"}
            />
            <input
              onChange={(ev) => setUsername(ev.target.value)}
              value={username}
              placeholder={"Benutzername"}
              autoCapitalize={"no"}
              autoCorrect={"no"}
            />
            <input
              onChange={(ev) => setPassword(ev.target.value)}
              value={password}
              placeholder={"Passwort"}
              type={"password"}
              autoCapitalize={"no"}
              autoCorrect={"no"}
            />
            <input
              onChange={(ev) => setInvite(ev.target.value)}
              value={invite}
              placeholder={"Einladungscode"}
              autoCapitalize={"no"}
              autoCorrect={"no"}
            />
            <button onClick={() => onSubmit()}>Registrieren</button>
          </form>

          <Link
            to={"/auth/login"}
            className={styles.mindChangedLink}
            aria-label={"Einloggen"}
            state={locationState}
          >
            Ich habe schon einen Account
          </Link>
        </>
      )}
    </>
  );
};

export default RegisterView;
