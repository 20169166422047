import React, { useState } from "react";

import styles from "./AuthViews.module.scss";

import { ReactComponent as Logo } from "../../logo.svg";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { logIn } from "../../features/auth/authSlice";

import { Key } from "lucide-react";
import { AuthLocationState } from "../../layouts/AuthLayout";
import LoginMutation from "../../mutations/LoginMutation";
import Helmet from "react-helmet";

const LoginView = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const location = useLocation();

  const locationState = location.state as AuthLocationState;

  const onSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      const res = await LoginMutation(username, password);

      const jwt = res.login;

      dispatch(logIn(jwt));
    } catch (e: any) {
      setError("Ein Fehler ist aufgetreten: " + e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo width={64} height={64} />
      <Helmet>
        <title>Bei Co2Admin anmelden</title>
      </Helmet>

      <h1>Login</h1>

      {loading && (
        <div className={styles.loader}>
          <Key />
          <span>Login läuft...</span>
        </div>
      )}

      {!loading && (
        <>
          {error !== "" && <span className={styles.error}>{error}</span>}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className={styles.authForm}
          >
            <input
              onChange={(ev) => setUsername(ev.target.value)}
              value={username}
              placeholder={"Benutzername"}
            />
            <input
              onChange={(ev) => setPassword(ev.target.value)}
              value={password}
              placeholder={"Passwort"}
              type={"password"}
            />
            <button onClick={() => onSubmit()}>Anmelden</button>
          </form>

          <Link
            to={"/auth/register"}
            className={styles.mindChangedLink}
            aria-label={"Registrieren"}
            state={locationState}
          >
            Ich habe kein Passwort
          </Link>
        </>
      )}
    </>
  );
};

export default LoginView;
