import React, { useContext, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import {
  SchoolDetailQuery,
  SchoolDetailQuery$variables,
} from "./__generated__/SchoolDetailQuery.graphql";
import { DisposeFn } from "relay-runtime";
import { Link, useParams } from "react-router-dom";
import { SchoolsSlideOverTitleContext } from "./Schools";

//import styles from "./SchoolDetail.module.scss";
import { Backpack } from "lucide-react";

type Props = {
  initialQueryRef: PreloadedQuery<SchoolDetailQuery> | null | undefined;
  fetch: (variables: SchoolDetailQuery$variables) => void;
  dispose: DisposeFn;
};

type PropsFinal = {
  initialQueryRef: PreloadedQuery<SchoolDetailQuery>;
};

const SchoolDetailInner = ({ initialQueryRef }: PropsFinal) => {
  //const [reconfigureRoom, reconfiguringRoom] = useReconfigureRoomMutation();
  //const navigate = useNavigate();

  const data = usePreloadedQuery(
    graphql`
      query SchoolDetailQuery($id: ID!) {
        school(id: $id) {
          id
          name
          #classes(first: 5) {
          #    edges {
          #        node {
          #            id
          #            name
          #        }
          #    }
          #}
        }
      }
    `,
    initialQueryRef
  );

  const titleSetContext = useContext(SchoolsSlideOverTitleContext);

  titleSetContext && data.school?.name && titleSetContext(data.school.name);

  return (
    <>
      <pre>{JSON.stringify(data, null, 2)}</pre>
      <Link to={`/schools/${data.school.id}/classes`}>
        <Backpack /> Alle Klassen
      </Link>
    </>
  );
};

const SchoolDetail = ({ initialQueryRef, fetch, dispose }: Props) => {
  const { schoolId } = useParams();

  useEffect(() => {
    if (!schoolId) return;
    fetch({ id: schoolId });

    return () => {
      dispose();
    };
  }, [schoolId, dispose, fetch]);

  return initialQueryRef ? (
    <SchoolDetailInner initialQueryRef={initialQueryRef} />
  ) : null;
};

export default SchoolDetail;
