import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AuthState {
  jwt: string | null;
  status: "logged_in" | "logged_out";
}

const initialState: AuthState = {
  jwt: null,
  status: "logged_out",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
      state.status = "logged_in";
    },
    logOut: (state) => {
      state.jwt = null;
      state.status = "logged_out";
    },
  },
});

export const { logIn, logOut } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
