import React from "react";
import { usePaginationFragment } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SchoolsTableFragment$key } from "./__generated__/SchoolsTableFragment.graphql";
import styles from "./SchoolsTable.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {
  initialQueryRef: SchoolsTableFragment$key;
};

const SchoolsTable = ({ initialQueryRef }: Props) => {
  const { data } = usePaginationFragment(
    graphql`
      fragment SchoolsTableFragment on Query
      @refetchable(queryName: "SchoolsTableFragment") {
        schools(after: $after, first: $first)
          @connection(key: "SchoolsTableFragment_schools") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    initialQueryRef
  );

  const navigate = useNavigate();

  return (
    <div className={styles.schoolsTableWrapper}>
      <table className={styles.schoolsTable}>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          {data.schools?.edges.map((edge) => {
            return (
              <tr
                onClick={() => {
                  navigate("/schools/" + edge.node.id);
                }}
                key={edge.node.id}
              >
                <td>
                  {/*edge.node.debug && <span className={styles.badge + " " + styles.blue}><Trans i18nKey={"rooms.debug"}>Debug</Trans></span>}
                            {!edge.node.active && <span className={styles.badge + " " + styles.red}><Trans i18nKey={"rooms.inactive"}>Inactive</Trans></span>}
                            {edge.node.active && <span className={styles.badge + " " + styles.green}><Trans i18nKey={"rooms.active"}>Active</Trans></span>*/}
                </td>
                <td>{edge.node.name}</td>
                <td>{edge.node.id}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SchoolsTable;
