import React, { useState } from "react";

import { Link, NavLink, useOutlet } from "react-router-dom";
import { Home, BookOpen, LogOut, GraduationCap } from "lucide-react";
import { Squash as Hamburger } from "hamburger-react";

import { ReactComponent as Logo } from "../logo.svg";

import styles from "./PanelLayout.module.scss";
import { useAppDispatch } from "../app/hooks";
import { logOut } from "../features/auth/authSlice";
import { LoaderSuspense } from "../components/common/Loader";

const PanelLayout = () => {
  const outlet = useOutlet();
  const [sideNavExpanded, setSideNavExpanded] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <div className={styles.panel}>
      <a href={"#main"} className={styles.skipToContent}>
        Zum Inhalt springen
      </a>
      <a href={"#navigation"} className={styles.skipToContent}>
        Zur Navigation springen
      </a>
      <div className={styles.topBar}>
        <div className={styles.hamburger}>
          <Hamburger
            toggle={setSideNavExpanded}
            toggled={sideNavExpanded}
            rounded={true}
            duration={0.25}
            distance={"lg"}
          />
        </div>
        <Link to={"/"} className={styles.logo}>
          <Logo /> <span>Co2Admin</span>
        </Link>
        <a
          href={"https://docs.co2fsz2.de"}
          target={"_blank"}
          rel={"noreferrer"}
          title={"Dokumentation"}
        >
          <BookOpen />
          <span> Dokumentation</span>
        </a>
        <button
          onClick={() => {
            dispatch(logOut());
          }}
          title={"Logout"}
        >
          <LogOut />
          <span> Logout</span>
        </button>
      </div>
      <div className={styles.content}>
        <div
          className={
            styles.navUnderlay + (sideNavExpanded ? " " + styles.show : "")
          }
          onClick={() => setSideNavExpanded(false)}
        />
        <nav
          id={"navigation"}
          className={sideNavExpanded ? styles.expanded : ""}
        >
          <NavLink to={"/"} onClick={() => setSideNavExpanded(false)}>
            <Home />
            <span>Dashboard</span>
          </NavLink>
          <NavLink to={"/schools"} onClick={() => setSideNavExpanded(false)}>
            <GraduationCap />
            <span>Meine Schulen</span>
          </NavLink>
        </nav>
        <main id={"main"}>
          <LoaderSuspense>{outlet}</LoaderSuspense>
        </main>
      </div>
    </div>
  );
};

export default PanelLayout;
