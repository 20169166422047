import React from "react";
import { graphql } from "babel-plugin-relay/macro";

import { PreloadedQuery, usePreloadedQuery } from "react-relay/hooks";
import { DashboardQuery } from "./__generated__/DashboardQuery.graphql";
import styles from "./Dashboard.module.scss";
import DashMyClasses from "./DashMyClasses";

type Props = {
  initialQueryRef: PreloadedQuery<DashboardQuery>;
};

const Dashboard = (props: Props) => {
  const data = usePreloadedQuery<DashboardQuery>(
    graphql`
      query DashboardQuery($after: ID, $first: Int) {
        self {
          firstName
          lastName
          email
          username
          id
          role
        }
        ...DashMyClassesFragment
      }
    `,
    props.initialQueryRef
  );

  const name = data.self?.firstName || data.self?.username;
  // "ANONYMOUS" | "GLOBAL_ADMIN" | "SCHOOL_ADMIN" | "STUDENT" | "TEACHER" | "USER"

  return (
    <>
      <h1>Moin {name}!</h1>

      {!(
        data.self.role === "GLOBAL_ADMIN" ||
        data.self.role === "SCHOOL_ADMIN" ||
        data.self.role === "TEACHER"
      ) && (
        <div className={styles.dashboardWarning}>
          <span>Ungültige Rolle: {data.self.role}</span>
          <p>
            Dein Benutzer ist kein Mitglied einer administrativen Rolle. Dies
            wird die Verwendung des Co2Admin-Interfaces stark einschränken, und
            der Zugriff auf viele Ressourcen wird verwehrt. Sollte dies ein
            Fehler sein, wende dich bitte an deinen Administrator.
          </p>
        </div>
      )}

      <div className={styles.dashboardGrid}>
        <DashMyClasses initialQueryRef={data} />
      </div>
    </>
  );
};

export default Dashboard;
