import React from "react";
import styles from "./List.module.scss";
import { Box, Loader } from "lucide-react";
import { LoadMoreFn } from "react-relay/relay-hooks/useLoadMoreFunction";

type Props = {
  children?: React.ReactNodeArray;
  isLoadingNext?: boolean;
  hasNext?: boolean;
  loadNext?: LoadMoreFn<any> | (() => any);
  className?: string;
};

const List = (props: Props) => {
  return (
    <div
      className={styles.list + (props.className ? " " + props.className : "")}
    >
      {(!props.children || props.children.length === 0) && (
        <span className={styles.eol}>
          <Box
            width={50}
            height={50}
            strokeWidth={1}
            strokeDasharray={"2px 4px"}
          />
          <br />
          Keine Einträge.
        </span>
      )}

      {props.children}

      {props.isLoadingNext && (
        <div className={styles.loader} title={"Lade Daten..."}>
          <Loader />
        </div>
      )}
      {!props.isLoadingNext &&
        props.children &&
        props.children.length > 0 &&
        (props.hasNext ? (
          <button className={styles.loadMore} onClick={() => props.loadNext}>
            Mehr laden
          </button>
        ) : (
          <span className={styles.eol}>Keine weiteren Einträge</span>
        ))}
    </div>
  );
};

export default List;
