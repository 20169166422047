import React, { useEffect } from "react";

import styles from "./AuthLayout.module.scss";
import { Link, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { UserPlus, User } from "lucide-react";

import { ReactComponent as Logo } from "../logo.svg";
import { useAppSelector } from "../app/hooks";
import { selectAuth } from "../features/auth/authSlice";

export type AuthLocationState = {
  location?: Location;
};

const AuthLayout = () => {
  const outlet = useOutlet();
  const location = useLocation();

  const locationState = location.state as AuthLocationState;

  const authState = useAppSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.status === "logged_in") {
      if (locationState && locationState.location) {
        navigate(locationState.location, { replace: true });
      }
      navigate("/", { replace: true });
    }
  }, [authState, locationState, navigate]);

  return (
    <div className={styles.auth}>
      <div className={styles.background} />
      <div className={styles.container}>
        <div className={styles.inner}>
          {outlet || (
            <>
              <Logo width={64} height={64} />
              <h1>Co2Admin</h1>
              <h2>Kennen wir uns schon?</h2>

              <div className={styles.splitChoice}>
                <Link to={"./login"} state={locationState}>
                  <User />
                  <span>Ja, direkt zum Login</span>
                </Link>
                <Link to={"./register"} state={locationState}>
                  <UserPlus />
                  <span>Nein, zur Registrierung</span>
                </Link>
              </div>
            </>
          )}
        </div>
        <footer>
          Co2Admin |{" "}
          <a
            href={"https://docs.co2fzs2.de"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            Hilfe
          </a>
        </footer>
      </div>
    </div>
  );
};

export default AuthLayout;
