import { commitMutation } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import RelayEnvironment from "../RelayEnvironment";
import {
  RegisterMutation as RegisterMutationType,
  RegisterMutation$data,
  RegisterMutation$variables,
} from "./__generated__/RegisterMutation.graphql";

const mutation = graphql`
  mutation RegisterMutation($registerInput: RegisterInput!) {
    register(input: $registerInput)
  }
`;

const RegisterMutation = (
  firstName: string,
  lastName: string,
  email: string,
  username: string,
  password: string,
  inviteCode: string
): Promise<RegisterMutation$data> => {
  return new Promise<RegisterMutation$data>((resolve, reject) => {
    const variables: RegisterMutation$variables = {
      registerInput: {
        firstName,
        lastName,
        username,
        email,
        password,
        inviteCode,
      },
    };

    commitMutation<RegisterMutationType>(RelayEnvironment({}), {
      mutation: mutation,
      variables,
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          reject(
            errors
              .map((error) => {
                return error.message;
              })
              .join(", ")
          );
          return;
        }

        resolve(response);
      },
      onError: reject,
    });
  });
};

export default RegisterMutation;
