import React, { useEffect, useState } from "react";
import { useNavigate, useOutlet, useParams } from "react-router-dom";

import styles from "./Classes.module.scss";
import { PreloadedQuery, usePreloadedQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import {
  ClassesQuery,
  ClassesQuery$variables,
} from "./__generated__/ClassesQuery.graphql";
import { X } from "lucide-react";
import { LoaderSuspense } from "../../../common/Loader";
import ClassesTable from "./ClassesTable";
import { DisposeFn } from "relay-runtime";

type PropsFinal = {
  initialQueryRef: PreloadedQuery<ClassesQuery>;
};

type Props = {
  initialQueryRef: PreloadedQuery<ClassesQuery> | null | undefined;
  fetch: (variables: ClassesQuery$variables) => void;
  dispose: DisposeFn;
};

const ClassesInner = ({ initialQueryRef }: PropsFinal) => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const data = usePreloadedQuery(
    graphql`
      query ClassesQuery($school: ID!, $first: Int, $after: ID) {
        school(id: $school) {
          id
          name
        }

        ...ClassesTableFragment
      }
    `,
    initialQueryRef
  );

  const defaultTitle = "Details";

  const [title, setTitle] = useState(defaultTitle);

  return (
    <div className={styles.classesContainer}>
      <div
        className={
          styles.classesOverview + (outlet ? " " + styles.leaveSpace : "")
        }
      >
        <h1>Klassen in "{data.school.name}"</h1>

        <ClassesTable initialQueryRef={data} />
      </div>

      <div className={styles.slideOver + (outlet ? " " + styles.active : "")}>
        <ClassesSlideOverTitleContext.Provider value={setTitle}>
          <div className={styles.slideOverHeader}>
            <span>{title}</span>
            <button
              onClick={() => navigate(`/schools/${data.school.id}/classes`)}
            >
              <X />
            </button>
          </div>
          <div className={styles.slideOverContent}>
            <LoaderSuspense>{outlet}</LoaderSuspense>
          </div>
        </ClassesSlideOverTitleContext.Provider>
      </div>
    </div>
  );
};

export const ClassesSlideOverTitleContext = React.createContext<React.Dispatch<
  React.SetStateAction<string>
> | null>(null);

const Classes = ({ initialQueryRef, fetch, dispose }: Props) => {
  const { schoolId } = useParams();

  useEffect(() => {
    if (!schoolId) return;
    fetch({ school: schoolId });

    return () => {
      dispose();
    };
  }, [schoolId, dispose, fetch]);

  return initialQueryRef ? (
    <ClassesInner initialQueryRef={initialQueryRef} />
  ) : null;
};

export default Classes;
